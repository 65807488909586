import './App.css';
import Navbar from "./components/Navbar";
import Landing from "./Views/Landing";

function App() {
    return (
        <>
            <Landing />
        </>
    );
}

export default App;
